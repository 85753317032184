/* eslint  no-unused-vars: 0 */
import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import {AlpineContainer} from './gridContainer';
import axios from 'axios';
import ApiUtils from '../../utils/apiUtils';
import Utils from '../../utils/utils';
import {useSearchParams} from 'react-router-dom';
const _ = require('lodash');


require('./grid-styles.scss');


const MyPaginatedGrid = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dragStart, setDragStart] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [tempGridApi, setTempGridApi] = useState(null);
  const [currentPage, setCurrentPage] = useState(props.pagination.currentPage);
  const [itemsPerPage, setItemsPerPage] = useState(props.pagination.itemsPerPage);
  const currUrlSearchParams = useRef(props.defFilterParams?.[0]);
  const additionalFilters = useRef(props.additionalFilters);

  //console.log('My PaginatedGrid props');
  //console.log(props);
  //console.log(props.fieldsToFilterOnEnter);


  const defaultColDef = useMemo( ()=> ({
    flex: 1,
    filter: true,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    suppressMenu: true,
    //debounceMs: 100,
  }), []);

  const formatFilterModel = (model) => {

    for(const key of Object.keys(model)) {
      if (key === "cityInspection.requirement") {
        if (model["cityInspection.requirement"]["cityInspection.needsLadder"]) {
          model["cityInspection.needsLadder"] = model["cityInspection.requirement"]["cityInspection.needsLadder"];
        }
        model["cityInspection.requirement"] = model["cityInspection.requirement"]["cityInspection.requirement"];
      }
    }

    //console.log('new model');
    //console.log(model);

    for(const value of Object.values(model)) {
      if(value.dateFrom) value.dateFrom = Utils.formatLocalTimeToIsoString(value.dateFrom, true);
      if(value.dateTo) value.dateTo = Utils.formatLocalTimeToIsoString(value.dateTo, true);
    }

    return model;
  };

  const onColumnMoved =useMemo(()=>(params)=> {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem(props.ls_name, columnState);

  },[]);

  const onCellDoubleClicked = useMemo(()=>(params)=> {
    if(props.onCellDoubleClicked) {
      props.onCellDoubleClicked(params);
    }
  },[]);

  // Thanh 05/01/24
  const onTempGridReady = useMemo(() => (params) => {
    setTempGridApi(params.api);

    if (props.registerTempGrid)
    {
      props.registerTempGrid(params, props.fetchParams);
    }    
  },[]);

  const onGridReady = useMemo(()=>(params)=> {
    setGridApi(params.api);
    if(props.defFilterParams && Array.isArray(props.defFilterParams) && props.defFilterParams.length === 2){
      try{
        setTimeout(()=> {
          var filterComponent = params.api.getFilterInstance(props.defFilterParams[0]);
          filterComponent.setModel({
            filterType: 'set',
            values:[props.defFilterParams[1]]
          });
          params.api.eventService.addEventListener('filterModified', (e) => {
            removeQueryParams(currUrlSearchParams.current);
            currUrlSearchParams.current = null;
          });
          filterComponent.onFilterChanged();
        },150);

      }catch (e){}
    }

    props.registerGrid(params);
    let columnState = JSON.parse(localStorage.getItem(props.ls_name));

    if (columnState) {
      params.columnApi.applyColumnState({state:columnState, applyOrder: true});
    }
  },[]);


  useEffect(()=>{
    if(!gridApi) return;
    additionalFilters.current = props.additionalFilters;
    gridApi.onFilterChanged();
  },[props.additionalFilters]);

  const portalFiltersToAgGridModel = ()=> {

    //console.log('portal filter to AG Grid');

    const agGridFilters = {};
    if(!additionalFilters.current) return agGridFilters;

    for (const [field, filter] of Object.entries(additionalFilters.current)) {
      if(filter.param === 'empty' || filter.param === 'notEmpty') {
        agGridFilters[field] = {
          type: filter.param,
          filterType: filter.type
        };
      } else if(filter.value && filter.value.length !== 0){
        agGridFilters[field] = {
          type: filter.param,
          filterType: filter.type,
          filter: filter.value
        };
      }

    }

    //console.log('agGridFilter');
    //console.log(agGridFilters);

    return agGridFilters;

  };

  const getServerSideDataSource = useMemo(()=>(fetchParams, companyId, limit = 10) => {
    const api = fetchParams.url;
    const options = fetchParams.options;

    return {
      getRows: async (params) => {
        let filterModel;

        //console.log('alljobsgrid => params');
        //console.log(params);

        if(currUrlSearchParams.current){

          //console.log('filters on search ');

          const instance = params.api.getFilterInstance(currUrlSearchParams.current);
          filterModel = {[currUrlSearchParams.current]: instance.appliedModel};

          // console.log('instance');
          // console.log(instance);
          // console.log('initial default filter');
          // console.log(filterModel);

        } else {
            //console.log('grid filter');
          filterModel = params.request?.filterModel || {};
        }
        const fetch = async ()=> {
          if(gridApi) gridApi.showLoadingOverlay();

          const page = Math.ceil(params.request.endRow / limit);
          filterModel = {...formatFilterModel(filterModel),...portalFiltersToAgGridModel()};

          // Thanh 02/024/24 
          //console.log('filter model #2');
          //console.log(filterModel);
         
          if (filterModel['jobNumber'] && fetchParams.respKey == 'stJobs') {
            if (filterModel['jobNumber'].filter.toString().length < 6) {
              return undefined;
            }
          }
          if (filterModel['jobNumber'] && fetchParams.respKey != 'stJobs') {
            if (filterModel['jobNumber'].filter.toString().length < 7) {
              return undefined;
            }
          }
          //console.log('after filter modal company id =>' + companyId);

          let requestParams = {limit: limit, page: page, filterModel: filterModel, sortModel: params.request?.sortModel};
          if(options?.status) {
            requestParams = {...requestParams, status: options.status};
          }
          if(options?.tenantId) {
            requestParams = {...requestParams, tenantId: options.tenantId} 
          }
          if(options?.companyId) {
            requestParams = {...requestParams, companyId: options.companyId} 
          }
          if(options?.selectedDate) {
            requestParams = {...requestParams, selectedDate: options.selectedDate} 
          }
          
          if(companyId) {
            requestParams = {...requestParams, companyId: companyId};
          }

          let url = `${api}`;

          // console.log('url 2 => ' + url);
          // console.log('request param');
          // console.log(requestParams);
          // console.log(`start gettng userlist => ${new Date()}`);

          const authToken = await ApiUtils.getAccessToken();
          const userList = await axios.get(url, {params: requestParams, headers: {Authorization: authToken}});
 
          //console.log(`finish gettng userlist => ${new Date()}`);

          // Thanh 12/02/23
          //console.log('grid data');
          //console.log(url);
          //console.log(userList.data.data);

          return userList.data.data;
        };

        //props.pagination.setCurrentPage(undefined);
        //props.pagination.setTotalPageCount(undefined);
        //props.pagination.setTotalItemsCount(undefined);

        fetch()
          .then((data)=> {

            //console.log('data from URL');
            //console.log(data);

            if (data === undefined) {
              return;
            }
            //console.log('end of data');

            if(gridApi) gridApi.hideOverlay();
            const modifiedList = data[props.fetchParams.respKey].map((el) => {
              el = props.modifyDataElement ? props.modifyDataElement(el) : el;
              return el;
            });

            if(gridApi && (!modifiedList || modifiedList.length === 0)) {
              gridApi.showNoRowsOverlay();
            }
      
            props.pagination.setCurrentPage(data.currentPage);
            props.pagination.setTotalPageCount(data.pagesCount);
            props.pagination.setTotalItemsCount(data.totalRecords);
            params.success({rowData: modifiedList, rowCount: data.totalRecords});
          })
          .catch((e)=> {
            if(gridApi) {
              gridApi.showNoRowsOverlay();
            }
            console.log('FAILED TO FETCH');
            console.log('error', e);
            params.fail();
            props.pagination.setCurrentPage(0);
            props.pagination.setTotalPageCount(0);
            props.pagination.setTotalItemsCount(0);
            props.onFetchFail(e);
          });

      }
    };
  },[props.additionalFilters]);

  useEffect(() => {
    if(gridApi) {
      const dataSource = getServerSideDataSource(props.fetchParams, props.companyId, itemsPerPage);   

      //console.log('server datasource');
      //console.log(dataSource);
   
      gridApi.setServerSideDatasource(dataSource);
    }
  }, [gridApi, itemsPerPage, props.fetchParams.url, props.companyId, props.refreshTimestamp]);

  useEffect(() => {
    setCurrentPage(props.pagination.currentPage);
    setItemsPerPage(props.pagination.itemsPerPage);
  }, [props.pagination.currentPage, props.pagination.itemsPerPage]);

  // const clearCacheForInactivePages = () => {
  //   if (gridApi) {
  //     //const currentPageIndex = gridApi.paginationGetCurrentPage();
  //     //setActivePage(currentPageIndex); // Update active page stat
  //     console.log('current page = '  + currentPage);
  //     gridApi.forEachNode(node => {
  //       // Check if the row's page index is not the active page

  //       console.log('node');
  //       console.log(node);
  //       console.log('node page');
  //       console.log(Math.floor(node.rowIndex / gridApi.paginationGetPageSize()) + 1);

  //       if (Math.floor(node.rowIndex / gridApi.paginationGetPageSize()) + 1 !== currentPage) {
  //         node.setData(null); // Clear data for inactive rows
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if(gridApi) {

      // Subscribe to events to clear cache for inactive pages
      //gridApi.addEventListener('paginationChanged', clearCacheForInactivePages);

      //page index start from 0 in ag-grid
      gridApi.paginationGoToPage(currentPage-1);

      // return () => {
      //   // Unsubscribe from events to avoid memory leaks
      //   gridApi.removeEventListener('paginationChanged', clearCacheForInactivePages);
      // };
    }
  }, [currentPage, gridApi]);


  const updateColumnOrder = useMemo(()=>()=> {

    //console.log('inside updateColumnOrder');
    //console.log(props.columnDefs);

    if(!props.ls_name) return props.columnDefs;
    const columnSavedState = JSON.parse(localStorage.getItem(props.ls_name));
    try{
      if(columnSavedState) {
        let newColumnDef = new Array(props.columnDefs.length);
        props.columnDefs.forEach((el, i)=> {
          //console.log('el:::', el);
          const index = columnSavedState.findIndex((e)=> e.colId === el.field);
          newColumnDef[index] = el;
        });

        //console.log('save column defs');
        //console.log(props.columnDefs);
        return newColumnDef;

      } else {

        //console.log('column defs');
        //console.log(props.columnDefs);

        return props.columnDefs;
      }
    } catch(e) {
      return props.columnDefs;
    }
  },[]);


  const getGridDefaultOptions =useMemo(()=>()=> {
    return {
      reactUi: 'true',
      className: 'ag-theme-alpine',
      animateRows: 'true',
      // suppressRowClickSelection: 'true',
      //modules={modules}
      onColumnMoved: onColumnMoved,
      defaultColDef: defaultColDef,
      enableCellTextSelection: true,
      rowSelection: 'multiple',
      suppressHorizontalScroll: false,
      overlayLoadingTemplate:
        '<Typography> Please wait...</Typography>',
      overlayNoRowsTemplate: '<Typography> No records found</Typography>',
      headerHeight: 30,
      rowHeight: 20

    };
  },[]);
  const removeQueryParams = (p) => {
    const param = searchParams.get(p);

    if (param) {
      // 👇️ delete each query param
      searchParams.delete(p);

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };

  function onFirstDataRendered(params) {
    params.api.forEachNode(function (node) {
      node.setExpanded(node.id === '1');
    });
  }


  // useEffect(() => {

  //   if(!gridApi) return;

  //   const handleKeyPress = (e) => {
  //     if (e.key === 'Enter') {

  //       console.log('enter key pressed');

  //       gridApi.onFilterChanged(); // Apply the filter when "Enter" is pressed
  //     }
  //   };

  //   const addEnterKeyListener = () => {

  //     console.log('set enter key listener');
 
  //     const filterInstance = gridApi.getFilterInstance();

  //     console.log('filter instance');
  //     console.log(filterInstance);

  //     const filterInput = filterInstance?.gui?.querySelector('input');

  //     console.log('filter input');
  //     console.log(filterInput);

  //     if (filterInput) {
  //       filterInput.addEventListener('keypress', handleKeyPress);
  //     }
  //   };

  //   gridApi.addEventListener('filterOpened', addEnterKeyListener);

  //   return () => {
  //     gridApi.removeEventListener('filterOpened', addEnterKeyListener);
  //   };
  // }, []);

  
  const gridOptions = {
    suppressDragLeaveHidesColumns: true,
    
    // onFilterChanged: () => {
    //   console.log("Filter applied!");
    // },
    // onCellKeyPress: (event) => {

    //   console.log('inside oncellkeypress');
    //   console.log(event);

    //   console.log(props.fieldsToFilterOnEnter);

    //   if (!props.fieldsToFilterOnEnter) {

    //     console.log('no filter on enter');

    //     return;
    //   }
    //   // Check if Enter key was pressed and if the active cell is in the specific column
    //   if (event.event.key === 'Enter' && props.fieldsToFilterOnEnter.includes(event.colDef.field)) {
        
    //     console.log ('about to filter after "enter" key pressed');

    //     const filterInstance = event.api.getFilterInstance(searchColumnField);
  
    //     if (filterInstance) {
    //       // Set filter model based on current cell value
    //       filterInstance.setModel({
    //         type: 'contains',
    //         filter: event.value, // filter using the current cell value
    //       });
    //       event.api.onFilterChanged(); // Apply the filter
    //     }
    //   }
    // },

    //suppressColumnStateEvents: true,
    //suppressLocalStorage: true,
    //  maxBlocksInCache: 1
    //   enableColResize: true,
    //   enableColumnsToolPanel: true,
    //   enableSorting: false,
  };

  // const onToolPanelVisible = (event) => {
      //   const toolPanelVisible = event.toolPanels.filter(panel => panel.id === 'columns')[0].visible;
  //   if (!toolPanelVisible) {
  //     // Custom logic when the column tool panel is closed
  //     console.log('Column Tool Panel closed');
  //   }
  // };


  const onToolPanelVisibleChanged = useCallback((event) => {
   
    var columnState = JSON.stringify(event.columnApi.getColumnState());
    localStorage.setItem(props.ls_name, columnState);

  }, []);

  // const closeRequirementFilter = () => {

  //   //console.log('inside close requirement filter');

  //   gridApi.closeActivePopup(); // Close the active popup (filter dropdown)
  // };

  // const handleFilterChanged = () => {
  //   const columnDefs = gridApi.getColumnDefs();

  //   const newFilterValues = { ...filterValues }; // Create a copy of existing filter values

  //   columnDefs.forEach(columnDef => {
  //       if (columnDef.field && columnDef.yesNoToBoolean) {

  //           console.log('convert yes/no to boolean');
  //           const filterInstance = gridApi.getFilterInstance(columnDef.field); // Get filter instance for specific column
  //           const filterModel = filterInstance.getModel(); // Get filter model

  //           // Convert 'Yes' and 'No' values to boolean
  //           const convertedValues = filterModel.values.map(value => value === 'Yes');

  //           // Update newFilterValues object with converted values for the targeted column
  //           newFilterValues[columnDef.field] = convertedValues;
  //       } else if (columnDef.field) {
  //           // For other columns, keep the existing filter values
  //           const filterInstance = gridApi.getFilterInstance(columnDef.field); // Get filter instance for specific column
  //           const filterModel = filterInstance.getModel(); // Get filter model
  //           newFilterValues[columnDef.field] = filterModel.values;
  //       }
  //   });

  //   // Update filterValues state with new values
  //   setFilterValues(newFilterValues);
  // };

  // const onToolPanelSizeChanged = useCallback((event) => {
  //   console.log("toolPanelSizeChanged", event);
  // }, []);

  return (
    <AlpineContainer isTabOffset={props?.isTabOffset}  > 
      <AgGridReact
        {...getGridDefaultOptions()}
        pagination={true}
        rowModelType={'serverSide'}
        paginationPageSize={itemsPerPage}
        cacheBlockSize = {itemsPerPage}
        serverSideStoreType={'partial'}
        getContextMenuItems={props.contextMenu}
        columnDefs={updateColumnOrder()}
        rowData={props?.rowData || []}
        frameworkComponents={props.components}
        onGridReady={onGridReady}
        //enableSorting={true}
        suppressPaginationPanel={true}
        onCellDoubleClicked={onCellDoubleClicked}
        masterDetail={!!(props.masterDetail && !!props?.detailsCellRenderer)}
        detailCellRenderer={props.detailsCellRenderer}
        onFirstDataRendered={props.masterDetail ? onFirstDataRendered : null}
        gridOptions={gridOptions}
        enableColumnChooser={true}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}    
          
        // onFilterChanged={handleFilterChanged} 
        // context={{
        //   closeRequirementFilter: closeRequirementFilter
        // }}
      />
      <div style={{ display: 'none' }}>
         <div className="ag-theme-alpine" style={{ height: 0, width: 0 }}>
           <AgGridReact
             columnDefs={updateColumnOrder()}
             rowData={[]}
             onGridReady={onTempGridReady}
           />
         </div>
       </div>
    </AlpineContainer>

  );
};

MyPaginatedGrid.propTypes = {
  ls_name: PropTypes.string.isRequired,
  columnDefs: PropTypes.instanceOf(Object).isRequired,
  //contextMenu: PropTypes.instanceOf(Array).isRequired,
  contextMenu: PropTypes.instanceOf(Function).isRequired,  // Thanh 05/01/24
  fetchParams: PropTypes.shape({
    url: PropTypes.string.isRequired,
    respKey:  PropTypes.string.isRequired,
    //respKey:  PropTypes.instanceOf(String).isRequired,
  }),
  modifyDataElement: PropTypes.instanceOf(Function),
  onFetchFail: PropTypes.instanceOf(Function),

  //rowData: PropTypes.instanceOf(Array).isRequired,  // Thanh 05/01/24
  components: PropTypes.instanceOf(Object),
  registerGrid: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    setTotalPageCount: PropTypes.func.isRequired,
    setTotalItemsCount: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.string,
  onCellDoubleClicked: PropTypes.func,
  //defFilterParams: PropTypes.instanceOf(Array).isRequired,
  additionalFilters: PropTypes.instanceOf(Object),
  masterDetail: PropTypes.bool,
  detailsCellRenderer: PropTypes.instanceOf(Function),
  refreshTimestamp: PropTypes.number
};

MyPaginatedGrid.defaultProps = {
  components: {},
  onFetchFail: ()=>{console.log('Fail to fetch data...');},
  modifyDataElement: undefined,
  companyId: undefined,
  onCellDoubleClicked: undefined,
  additionalFilters: {},
  masterDetail: false,
  detailsCellRenderer: undefined,
  refreshTimestamp: null,
};
export default MyPaginatedGrid;
